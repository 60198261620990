// icons/WeightIcon.jsx
import React from "react";

export const WeightIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size} // Naudojame size iš props
    height={size} // Naudojame size iš props
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0005 15L27.516 22.5M41.25 15C44.7445 15 46.4917 15 47.8702 15.5709C49.7077 16.3321 51.168 17.7922 51.929 19.6299C52.5 21.0082 52.5 22.7555 52.5 26.25V40.5C52.5 44.7005 52.5 46.8005 51.6825 48.405C50.9635 49.8162 49.8162 50.9635 48.405 51.6825C46.8005 52.5 44.7005 52.5 40.5 52.5H19.5C15.2996 52.5 13.1994 52.5 11.5951 51.6825C10.1839 50.9635 9.0365 49.8162 8.31745 48.405C7.5 46.8005 7.5 44.7005 7.5 40.5V26.25C7.5 22.7555 7.5 21.0082 8.0709 19.6299C8.8321 17.7922 10.2922 16.3321 12.1299 15.5709C13.5082 15 15.2555 15 18.75 15M25 42.5H35M26.3538 7.5H33.647C36.3493 7.5 37.7005 7.5 38.7005 7.96353C40.034 8.58155 41.028 9.75498 41.4185 11.172C41.7112 12.2347 41.4892 13.5674 41.045 16.233C40.7155 18.2094 40.5508 19.1976 40.1123 19.9695C39.5278 20.9986 38.597 21.787 37.4857 22.1944C36.6525 22.5 35.6505 22.5 33.647 22.5H26.3538C24.3502 22.5 23.3483 22.5 22.5149 22.1944C21.4037 21.787 20.4729 20.9986 19.8884 19.9695C19.45 19.1976 19.2853 18.2094 18.9559 16.233C18.5116 13.5674 18.2895 12.2347 18.5823 11.172C18.9726 9.75498 19.9667 8.58155 21.3002 7.96353C22.3004 7.5 23.6515 7.5 26.3538 7.5Z"
      stroke={color} // Naudojame color iš props
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BicepIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 43 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.2689 3.57538C32.6942 1.73834 23.8571 -2.69451 19.0459 2.29192C14.2345 7.27824 19.3462 10.8011 21.1836 11.649C23.0208 12.4969 28.2696 13.7923 30.1729 10.4681C30.6806 11.7689 30.338 22.5886 27.3379 25.3396C25.8176 23.8902 23.6233 22.9802 21.1837 22.9802C19.1806 22.9802 17.3434 23.5938 15.9085 24.6155C14.0142 21.5751 10.4302 19.5222 6.31725 19.5222C4.3452 19.5222 2.49555 19.9962 0.893637 20.8226V21.8856C0.892314 21.8853 0.887939 21.8843 0.887939 21.8843V47.9973C0.887939 47.9973 8.49888 48.3911 9.1077 39.5992C9.1075 39.5987 9.10719 39.5982 9.10719 39.5977C10.9587 42.2864 16.8578 44.3328 20.4929 44.2122C26.2027 44.0228 33.7898 43.7725 40.2169 37.6111C40.8109 37.0201 41.2873 36.3221 41.6109 35.5499C41.6109 35.5497 41.6109 35.5497 41.6109 35.5495C44.5844 29.364 35.8434 5.41241 34.2689 3.57538Z"
      fill={color}
    />
  </svg>
);

export const WaistIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.7003 3C38.7003 3 34.7334 12.2987 34.7334 19.9999C34.7334 22.4657 35.5785 24.5291 36.7167 26.5841M36.7167 26.5841C38.027 28.9492 39.7257 31.303 40.9725 34.2462C41.9622 36.5819 42.667 39.2886 42.667 42.6667M36.7167 26.5841H8.95004M6.96667 3C6.96667 3 10.9333 12.2987 10.9333 19.9999C10.9333 22.4657 10.0884 24.5291 8.95004 26.5841M8.95004 26.5841C7.63992 28.9492 5.94112 31.303 4.69429 34.2462C3.70483 36.5819 3 39.2886 3 42.6667M4.69414 34.2462C4.69414 34.2462 18.8667 37.0001 22.8334 42.6667C26.8 37.0001 40.9722 34.2462 40.9722 34.2462"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ChestIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 46 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 3C15 5.53217 15 7.45029 15 8.75433C15 10.7104 13.9486 11.4645 9.98636 12.5073C6.02415 13.5499 4.85107 14.1645 3.92553 15.9641C3.30851 17.1638 3 19.0162 3 21.5211V41"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M34.9338 23C35.2031 26.2625 34.6507 29.1119 33.2768 31.548C31.9029 33.9842 29.4773 35.4682 26 36"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.066 23C10.7975 26.2625 11.3482 29.1119 12.7182 31.548C14.088 33.9842 16.5152 35.4682 20 36"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 48C12.3333 46.2639 13 44.3763 13 42.337C13 40.2977 13 37.1853 13 33"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M35 48C33.6667 46.2639 33 44.3763 33 42.337C33 40.2977 33 37.1853 33 33"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M31 3C31 5.53217 31 7.45029 31 8.75433C31 10.7104 32.0514 11.4645 36.0136 12.5073C39.9758 13.5499 41.149 14.1645 42.0744 15.9641C42.6915 17.1638 43 19.0162 43 21.5211V41"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
  </svg>
);

export const ShouldersIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0938 5.19971L17.625 11.0747L8.57677 12.3674L23.5 16.098L38.4232 12.3674L29.375 11.0747L27.9062 5.19971L25.4031 6.86857L24.3014 11.2751H22.6986L21.5969 6.86848L19.0938 5.19971ZM6.36878 13.5183L1.46875 18.4185L5.875 24.2935L8.24061 21.9279L10.6841 14.5973L6.36878 13.5183ZM40.6312 13.5183L36.3159 14.5972L38.7595 21.9279L41.125 24.2935L45.5312 18.4185L40.6312 13.5183ZM12.292 14.9992L10.4447 20.5409L11.7125 25.6123L22.6738 31.7019V17.5948L12.292 14.9992ZM34.708 14.9992L24.3262 17.5948V31.7019L35.2875 25.6123L36.5552 20.5409L34.708 14.9992ZM13.2188 28.3391V33.5086L22.6738 36.32V33.5919L13.2188 28.3391ZM33.7812 28.3391L24.3262 33.592V36.32L33.7812 33.5086V28.3391ZM13.2188 35.2735V40.4497L22.6738 41.8004V38.0847L13.2188 35.2735ZM33.7812 35.2735L24.3262 38.0847V41.8005L33.7812 40.4498V35.2735Z"
      fill={color}
    />
  </svg>
);

export const BellyIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 47 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82668 3C9.60891 6.17822 11 10.3061 11 15.3837C11 23 4.96415 28.8297 3.95384 33C3.28031 35.7802 2.96348 39.1135 3.00334 43"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M40.1733 3C37.3912 6.17822 36 10.3061 36 15.3837C36 23 42.0359 28.8297 43.0462 33C43.7197 35.7802 44.0365 39.1135 43.9967 43"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M5 30C11.9627 34.6666 18.2856 37 23.9689 37C29.6521 37 35.6625 34.6666 42 30"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
    />
  </svg>
);

export const HipsIcon = ({ color = "currentColor", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 46 46"
    fill="none"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.7722 0.438089C37.5807 0.163635 37.2748 0 36.9395 0H9.06117C8.72969 0 8.42089 0.163635 8.22841 0.438089C8.0664 0.673011 -7.80396 24.2213 5.03607 45.5146C5.22044 45.8147 5.54901 46 5.90317 46H18.2617C18.7186 46 19.1191 45.6961 19.2344 45.2583C19.3734 44.7373 21.7334 35.969 22.998 29.1854C24.2627 35.969 26.6226 44.7369 26.7646 45.2583C26.8828 45.6964 27.2837 46 27.7393 46H40.0968C40.4539 46 40.7792 45.8147 40.9671 45.5146C53.8013 24.2213 37.9339 0.673011 37.7722 0.438089ZM9.6104 2.02033H36.3892C37.0503 3.06792 38.755 5.94174 40.3845 9.96977C37.5237 11.2166 27.1139 15.963 23 20.7882C18.89 15.9653 8.47695 11.217 5.61997 9.97009C7.24466 5.94369 8.94744 3.06824 9.6104 2.02033ZM17.4821 43.9797H6.4793C-0.215494 32.4413 1.88195 20.2062 4.89479 11.8611C7.83861 13.1543 18.4211 18.03 21.7735 22.5127C21.7693 22.5522 21.7528 22.5898 21.7547 22.6309C21.9955 26.5773 18.379 40.578 17.4821 43.9797ZM39.5281 43.9797H28.5166C27.6207 40.578 24.0022 26.5773 24.2469 22.6309C24.2488 22.5917 24.23 22.5564 24.2261 22.5146C27.5776 18.0339 38.161 13.1566 41.1049 11.8631C44.1196 20.2062 46.2171 32.4393 39.5281 43.9797ZM11.2818 40.961C11.6025 41.4166 11.4959 42.0446 11.0452 42.366C10.8676 42.4924 10.6654 42.5533 10.459 42.5533C10.1444 42.5533 9.83074 42.4094 9.6373 42.1291C4.29112 34.6019 6.03246 22.0017 6.10731 21.469C6.18637 20.9145 6.70709 20.5435 7.25081 20.6116C7.80231 20.6906 8.18597 21.2036 8.10723 21.7551C8.0894 21.8785 6.40834 34.0948 11.2818 40.961ZM36.3614 42.1291C36.1644 42.4091 35.8527 42.5533 35.5387 42.5533C35.3355 42.5533 35.1304 42.4959 34.9525 42.366C34.4989 42.0446 34.3942 41.4111 34.716 40.961C39.5994 34.0867 37.9102 21.8834 37.8927 21.757C37.814 21.2055 38.1967 20.6945 38.7492 20.6138C39.2877 20.5458 39.8107 20.9149 39.8936 21.4712C39.9685 22.0017 41.7124 34.6039 36.3614 42.1291Z"
      fill={color}
    />
  </svg>
);
